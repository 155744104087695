<template>
  <div
    class="result-container"
    :style="{
      backgroundImage: 'url(' + (faceResult == '1' ? img2 : faceResult == '2' ? img3 : img1) + ')',
    }"
  >
    <div class="result-main">
      <div class="p-t44 rem46 lh31">
        <span v-if="faceResult === '3'">审批中</span>
        <span v-if="faceResult === '1'">恭喜你</span>
        <span v-if="faceResult === '2'" class="c-FE4D4DFF">很抱歉</span>
      </div>
      <div class="rem30 lh31 c-8E929B">
        <span v-if="faceResult === '3'">您的信息已提交，额度审批中</span>
        <span v-if="faceResult === '1'">成功申请{{ busName }}</span>
        <span v-if="faceResult === '2'">您的综合评分不足，暂未审批通过</span>
      </div>
      <div class="result-body">
        <div class="rem30 lh21 mb7 f500">温馨提示</div>
        <div class="rem30 lh28 c-303133">
          <div v-if="faceResult === '3'" class="flex">
            <span class="icon-qq"></span> 审批结果将以<span class="c-4D7BFE">短信通知</span
            >您，请注意查收
          </div>

          <div v-if="faceResult === '1'">
            <div class="flex">
              <span class="icon-qq"></span>该额度可以在{{ busName }}项目内循环使用
            </div>
          </div>
          <div v-if="faceResult === '2'" class="flex">
            <span class="icon-qq"></span>建议<span class="c-4D7BFE">七天后</span>重新申请
          </div>
        </div>
      </div>
      <div class="result-btn m5">
        <van-button round block plain color="#4D7BFE" class="btn" @click="toIndex">
          <span class="rem34">返回首页</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { creditApplyApi, getLimitVarietyApi } from '@/apis/credit'

import { mapState } from 'vuex'
import Vue from 'vue'
import { Button } from 'vant'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Button)
export default {
  data() {
    return {
      img1: require('@/assets/images/payment/result1.png'),
      img2: require('@/assets/images/payment/result2.png'),
      img3: require('@/assets/images/payment/result3.png'),
      result: '',
      faceResult: '',
      busName: '',
      nodeStatus: '', // 流程状态
    }
  },
  created() {
    if (this.productCode.indexOf('cgd') != -1) {
      this.busName = '采易融'
    } else if (this.productCode.indexOf('kdd') != -1) {
      this.busName = '顺丰快递贷'
    }
    if (this.$route.query.outSts) {
      if (this.$route.query.outSts == '101') {
        this.faceResult = '1'
      } else {
        this.faceResult = '2'
      }
    } else {
      this.getLimitVariety()
    }
  },
  computed: {
    ...mapState({
      businessType: (state) => state.common.businessType,
      productCode: (state) => state.common.productCode,
      creditApplySeqNo: (state) => state.borrow.creditApplySeqNo,
      creditFaceToken: (state) => state.borrow.creditFaceToken,
    }),
  },
  methods: {
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    getLimitVariety() {
      const query = { productCode: this.productCode }
      getLimitVarietyApi(query).then((res) => {
        if (res.code === 200) {
          if (res.data.node > 4000 || res.data.limitStatus == '05') {
            this.getfaceResult()
          } else {
            this.faceResult = '2'
          }
        }
      })
    },
    // 授信申请
    getfaceResult() {
      // this.faceResult = res.data.scFaceSts
      creditApplyApi({
        businessType: this.businessType,
        creditApplySeq: this.creditApplySeqNo,
      })
        .then((result) => {
          if (result.code === 200) {
            if (result.data.outSts == '103') {
              this.faceResult = '3'
            } else if (result.data.outSts == '102') {
              this.faceResult = '2'
            } else if (result.data.outSts == '101') {
              this.faceResult = '1'
            }
          }
          this.hideloading()
        })
        .catch(() => {
          this.hideloading()
        })
    },
    toIndex() {
      this.$router.push({ path: this.$store.state.common.defaultIndex })
    },
  },
}
</script>

<style lang="less" scoped>
.result-container {
  padding: 0 0.42rem /* 21/50 */;
  background-size: 100% 8.4rem /* 420/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .result-body {
    background-color: #fff;
    margin-top: 0.72rem /* 36/50 */;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 0.38rem /* 19/50 */ 0.3rem /* 15/50 */;
    border-radius: 4px;
    .icon-qq {
      margin-top: 0.18rem /* 9/50 */;
      display: inline-block;
      width: 0.2rem /* 10/50 */;
      height: 0.2rem /* 10/50 */;
      border-radius: 50%;
      border: 0.04rem /* 2/50 */ solid #4d7bfe;
      box-sizing: border-box;
      margin-right: 0.1rem /* 5/50 */;
    }
  }

  .result-btn {
    margin-top: 0.82rem /* 41/50 */;
  }
  .btn {
    margin-top: 0.48rem /* 24/50 */;
  }
}
</style>
